import React from 'react';

import {IconTypes, Icon} from '@pexip/components';
import {createSignal} from '@pexip/signal';

import type {
    GroupedToolbarBtn,
    PluginContext,
    PluginElements,
    ToolbarBtn,
} from './types';
import {CustomIcon} from './components/Toolbar/CustomIcon';

export const updatePluginsElements = (
    pluginsElements: PluginContext,
    [chanId, pluginElements]: [string, PluginElements],
) => {
    const pluginIndex = Object.keys(pluginsElements).findIndex(
        id => id === chanId,
    );
    const pluginsElementsEntries = Object.entries(pluginsElements);
    if (pluginIndex !== -1) {
        pluginsElementsEntries.splice(pluginIndex, 1, [chanId, pluginElements]);
    }
    return Object.fromEntries(pluginsElementsEntries);
};

export const getIcon = (button: GroupedToolbarBtn | ToolbarBtn) => {
    return typeof button.icon === 'string' ? (
        <Icon
            source={IconTypes[button.icon as keyof typeof IconTypes]}
            size="compact"
            className="mr-2"
        />
    ) : (
        <CustomIcon button={button} />
    );
};

export type IframeToggleSignal = {
    channelId: string;
    frameId: string;
};
export const toggleSidePanelPluginSignal = createSignal<IframeToggleSignal>();
export const toggleDraggablePluginSignal = createSignal<IframeToggleSignal>();
export const toggleFixedPluginSignal = createSignal<IframeToggleSignal>();
